<!-- right drawer with filters for issues -->

<template>
  <v-card
    class="FiltersDrawer"
    :class="{
      'FiltersDrawer--mini': miniDrawer,
    }"
    dark
    elevation="0"
    color="darkBackground"
  >
    <v-toolbar
      color="darkBackground"
      class="FiltersDrawer__toolbar"
      :class="{ 'mb-6': !miniDrawer }"
    >
      <v-badge
        overlap
        :offset-x="28"
        :offset-y="28"
        :content="filterCounter || 0"
        :value="(filterCounter || 0) && miniDrawer"
        color="primary"
      >
        <v-btn
          key="toggle-mini"
          text
          tile
          :height="$vuetify.breakpoint.mdAndUp ? 64 : 56"
          :min-width="!miniDrawer ? ($vuetify.breakpoint.mdAndUp ? 64 : 56) : 64"
          :width="!miniDrawer ? ($vuetify.breakpoint.mdAndUp ? 64 : 56) : 64"
          color="white"
          :class="{
            'rounded-circle': !miniDrawer,
          }"
          style="transition: margin 200ms ease-in-out"
          @click="miniDrawer = !miniDrawer"
        >
          <v-icon v-text="miniDrawer ? 'mdi-filter-outline' :'mdi-close'" />
        </v-btn>
      </v-badge>

      <div
        v-if="!miniDrawer"
        class="FiltersDrawer__toolbar-title"
        v-text="$t('filter.Filter')"
      />

      <v-btn
        v-if="!miniDrawer"
        key="reset"
        :disabled="!canResetFilters"
        text
        :color="canResetFilters ? 'primary' : null"
        class="mr-4 ml-auto"
        @click="resetFilters()"
      >
        {{ $t('filter.Reset') }}
      </v-btn>
    </v-toolbar>

    <v-btn
      v-if="miniDrawer"
      class="FiltersDrawer__expand"
      text
      color="black"
      @click="miniDrawer = false"
    />
    <template v-else-if="filter">
      <div
        v-if="createCard"
        class="FiltersDrawer__count"
      >
        <span
          class="FiltersDrawer__count-label"
          v-text="$t('filter.SelectIssues')"
        />
        <span
          v-if="projectId != null"
          class="FiltersDrawer__count-value"
          v-text="getCounter('total')"
        />
      </div>

      <IssueFilterGroupsAndProjects
        v-if="projectId == null"
        class="FiltersDrawer__filter"
        :value="{
          selectedGroupIds: (filter.groups || '').split(',').filter(Boolean),
          selectedProjectIds: (filter.project || '').split(',').filter(Boolean),
        }"
        :filter="filter"
        dark
        sticky-top="64px"
        @input="setProjectsAndGroups"
      />

      <IssueFilterStatus
        class="FiltersDrawer__filter"
        :project-id="projectId"
        :value="(filter.status || '').split(',').filter(Boolean)"
        :filter="filter"
        dark
        sticky-top="64px"
        @input="setStatus"
      />

      <IssueFilterTotalScore
        v-if="projectId == null || (predefinedFields && predefinedFields.totalScore != null)"
        class="FiltersDrawer__filter"
        :project-id="projectId"
        :value="(filter.totalScore || '').split(',').filter(Boolean).map(Number)"
        :filter="filter"
        :show-more-button="!(filter && filter.probabilityScore && filter.criticalityScore)"
        :show-more.sync="expandScore"
        :show-more-text="$t('filter.MoreOptions')"
        :show-less-text="$t('filter.LessOptions')"
        dark
        sticky-top="64px"
        @input="setTotalScore"
      >
        <template #more>
          <IssueFilterCheckboxScore
            v-if="(projectId == null || predefinedFields.probabilityScore) && (expandScore || (filter && filter.probabilityScore))"
            class="FiltersDrawer__filter FiltersDrawer__filter--nested pb-4"
            :project-id="projectId"
            :value="(filter.probabilityScore || '').split(',').filter(Boolean).map(Number)"
            :filter="filter"
            score-prop="probabilityScore"
            :title="$t('filter.Probability')"
            dark
            sticky-top="64px"
            @input="setProbabilityScore"
          />

          <IssueFilterCheckboxScore
            v-if="(projectId == null || predefinedFields.criticalityScore) && (expandScore || (filter && filter.criticalityScore))"
            class="FiltersDrawer__filter FiltersDrawer__filter--nested pb-0"
            :project-id="projectId"
            :value="(filter.criticalityScore || '').split(',').filter(Boolean).map(Number)"
            :filter="filter"
            score-prop="criticalityScore"
            :title="$t('filter.Criticality')"
            dark
            sticky-top="64px"
            @input="setCriticalityScore"
          />
        </template>
      </IssueFilterTotalScore>

      <IssueFilterOverdue
        :value="(filter.overdue || '').split(',').filter(Boolean)"
        class="SecondaryFiltersDrawer__filter"
        :project-id="projectId"
        :filter="filter"
        dark
        @input="setOverdue($event)"
      />

      <IssueFilterCompleted
        :value="(filter.isCompleted || '').split(',').filter(Boolean).map(v => v === 'true')"
        class="SecondaryFiltersDrawer__filter"
        :project-id="projectId"
        :filter="filter"
        dark
        @input="setIsCompleted($event)"
      />

      <IssueFilterCustom
        v-for="field in customFilterableFields"
        :key="field.name"
        class="FiltersDrawer__custom-filter"
        :project-id="projectId"
        :field="field"
        :value="getCustomFieldValue(field)"
        :filter="filter"
        dark
        @input="setCustomField(field, $event)"
      />

      <IssueFilterIp
        v-if="projectId && predefinedFields && predefinedFields.ips"
        class="FiltersDrawer__filter"
        :project-id="projectId"
        :value="(filter.ips || '').split(',').filter(Boolean)"
        :filter="filter"
        dark
        sticky-top="64px"
        @input="setIps"
      />

      <IssueFilterHostname
        v-if="projectId && predefinedFields && predefinedFields.hostnames"
        class="FiltersDrawer__filter"
        :project-id="projectId"
        :value="(filter.hostnames || '').split(',').filter(Boolean)"
        :filter="filter"
        dark
        sticky-top="64px"
        @input="setHostnames"
      />

      <div v-if="projectId && predefinedFields && predefinedFields.assets && issueSchema.assetVersion === ASSET_VERSION.COMPLEX">
        <IssueFilterIp
          class="FiltersDrawer__filter"
          :project-id="projectId"
          :value="(filter.ips || '').split(',').filter(Boolean)"
          :filter="filter"
          dark
          sticky-top="64px"
          @input="setIps"
        />

        <IssueFilterHostname
          class="FiltersDrawer__filter"
          :project-id="projectId"
          :value="(filter.hostnames || '').split(',').filter(Boolean)"
          :filter="filter"
          dark
          sticky-top="64px"
          @input="setHostnames"
        />

        <IssueFilterPort
          class="FiltersDrawer__filter"
          :project-id="projectId"
          :value="(filter.ports || '').split(',').filter(Boolean)"
          :filter="filter"
          dark
          sticky-top="64px"
          @input="setPorts"
        />
      </div>
    </template>
  </v-card>
</template>

<script>
import axios from 'axios'
import * as R from 'ramda'

import { SCORE, NON_FILTER_PARAMS, ISSUE_FIELD_TYPE, ASSET_VERSION } from '../constants'
import {
  replaceRoute,
  unorderedPlainObjectHash,
} from '../helpers'

import DashboardCard from '../store/orm/dashboardCard'
import IssueCounter from '../store/orm/issueCounter'

import IssueFilterCheckboxScore from '../components/IssueFilterCheckboxScore'
import IssueFilterCompleted from '../components/IssueFilterCompleted'
import IssueFilterCustom from '../components/IssueFilterCustom'
import IssueFilterHostname from '../components/IssueFilterHostname'
import IssueFilterPort from '@/components/IssueFilterPort'
import IssueFilterIp from '../components/IssueFilterIp'
import IssueFilterOverdue from '../components/IssueFilterOverdue'
import IssueFilterGroupsAndProjects from '../components/IssueFilterGroupsAndProjects'
import IssueFilterStatus from '../components/IssueFilterStatus'
import IssueFilterTotalScore from '../components/IssueFilterTotalScore'

const NO_COUNTER = '…'

export default {
  name: 'FiltersDrawer',

  components: {
    IssueFilterCheckboxScore,
    IssueFilterCompleted,
    IssueFilterCustom,
    IssueFilterHostname,
    IssueFilterPort,
    IssueFilterIp,
    IssueFilterOverdue,
    IssueFilterGroupsAndProjects,
    IssueFilterStatus,
    IssueFilterTotalScore,
  },

  props: {
    projectId: { type: String, default: null },
    cardId: { type: Number, default: null },
    createCard: { type: Boolean, default: false },
    filterQuery: { type: Object, default: () => ({}) },
  },

  data: () => ({
    ASSET_VERSION,
    SCORE,

    expandScore: false,
  }),

  computed: {
    issueStatus() {
      const { $store, projectId } = this
      return projectId && $store.getters['$issueStatus/getList'](projectId)
    },

    issueSchema() { return this.$store.getters['issueSchema/get'](this.projectId) },

    schemaFields() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/filterableFields'](projectId)
    },

    schemaFieldsLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/fieldsLookup'](projectId)
    },

    miniDrawer: {
      get() { return this.$store.state.appDrawer.miniDrawer },
      set(miniDrawer) {
        this.$store.commit('appDrawer/setMiniDrawer', miniDrawer)
        return !!miniDrawer
      },
    },

    card() {
      const { cardId } = this
      return cardId && DashboardCard.find(cardId)
    },

    filter() {
      const { $store, projectId, filterQuery } = this
      const storeFilter = $store.state.entities.issue.$filter[projectId || null]
      return storeFilter && {
        ...storeFilter,
        ...filterQuery,
      }
    },

    filterCounter() {
      return this.filter && Object.keys(this.filter).length
    },

    filterHash() {
      const { filter } = this
      return filter && unorderedPlainObjectHash(filter)
    },

    counters() {
      const { projectId, filter } = this
      return IssueCounter.getOrDefault(projectId, filter)
    },

    baseRoute() {
      const { $route, projectId, cardId, createCard } = this

      if (createCard) {
        return projectId
          ? {
            name: 'ProjectEditCard',
            params: { projectId },
            query: R.pick(NON_FILTER_PARAMS, R.clone($route.query)),
          }
          : {
            name: 'EditCard',
            query: R.pick(NON_FILTER_PARAMS, R.clone($route.query)),
          }
      } else if (cardId) {
        return projectId
          ? {
            name: 'ProjectCardIssueList',
            params: { projectId, cardId },
            query: R.pick(NON_FILTER_PARAMS, R.clone($route.query)),
          }
          : {
            name: 'CardIssueList',
            params: { cardId },
            query: R.pick(NON_FILTER_PARAMS, R.clone($route.query)),
          }
      } else {
        return projectId
          ? {
            name: 'ProjectIssueList',
            params: { projectId },
            query: {},
          }
          : {
            name: 'IssueList',
            query: {},
          }
      }
    },

    // a route to reset filters
    resetFiltersRoute() { return this.baseRoute },

    // we can reset filters if the filters are non-default for this card / issue list
    canResetFilters() {
      const { $route, $router, resetFiltersRoute } = this
      return $router.resolve(resetFiltersRoute).resolved.fullPath !== $route.fullPath
    },

    predefinedFields() { // as an object
      const { schemaFieldsLookup } = this
      return schemaFieldsLookup && R.filter(
        R.propEq('predefined', true),
        schemaFieldsLookup,
      )
    },

    customFilterableFields() { // as an array
      const { schemaFields } = this
      return schemaFields && R.filter(
        R.propEq('predefined', false),
        schemaFields,
      )
    },
  },

  watch: {
    filterHash: 'fetchCounters',
    customFilterableFields: { handler: 'fetchCounters', deep: true },

    projectId: {
      handler(projectId) {
        if (projectId) this.fetchAllIpsAndHostnames()
      },
      immediate: true,
    },
  },

  methods: {
    // getCustomFieldValue :: IssueSchemaField -> any[]
    getCustomFieldValue(field) {
      const { filter } = this
      const typeCast = R.cond([
        [R.propEq('type', ISSUE_FIELD_TYPE.INTEGER), x => parseInt(x, 10)],
        [R.propEq('type', ISSUE_FIELD_TYPE.FLOAT), Number],
        [R.T, R.always(R.identity)],
      ])(field)

      return (filter[field.name] || '')
        .split(',')
        .filter(Boolean)
        .map(typeCast)
    },

    // setProject :: str[] -> void
    setProjectsAndGroups({ selectedGroupIds, selectedProjectIds }) {
      const newProjectQ = selectedProjectIds.join(',')
      const newGroupsQ = selectedGroupIds.join(',')
      if (!newProjectQ && !newGroupsQ && !this.cardId) {
        this.setFilter(R.omit(['project', 'groups'], this.filter || {}))
      } else {
        this.setFilter(R.reject(R.isNil, {
          ...this.filter,
          project: newProjectQ || undefined,
          groups: newGroupsQ || undefined,
        }))
      }
    },

    // setStatus :: str[] -> void
    setStatus(status) {
      const newStatusQ = status.join(',')
      if (!newStatusQ && !this.cardId) {
        this.setFilter(R.omit(['status'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          status: newStatusQ,
        })
      }
    },

    // setTotalScore :: int[] -> void
    setTotalScore(scores) {
      const newTotalScoreQ = scores.join(',')
      if (!newTotalScoreQ && !this.cardId) {
        this.setFilter(R.omit(['totalScore'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          totalScore: newTotalScoreQ,
        })
      }
    },

    // setProbabilityScore :: int[] -> void
    setProbabilityScore(scores) {
      const newProbabilityScoreQ = scores.join(',')
      if (!newProbabilityScoreQ && !this.cardId) {
        this.setFilter(R.omit(['probabilityScore'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          probabilityScore: newProbabilityScoreQ,
        })
      }
    },

    // setCriticalityScore :: int[] -> void
    setCriticalityScore(scores) {
      const newCriticalityScoreQ = scores.join(',')
      if (!newCriticalityScoreQ && !this.cardId) {
        this.setFilter(R.omit(['criticalityScore'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          criticalityScore: newCriticalityScoreQ,
        })
      }
    },

    // setOverdue :: str[] -> void
    setOverdue(overdue) {
      const newQ = overdue.join(',')
      if (!newQ && !this.cardId) {
        this.setFilter(R.omit(['overdue'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          overdue: newQ,
        })
      }
    },

    // setIsCompleted :: bool[] -> void
    setIsCompleted(isCompleted) {
      const newQ = isCompleted.map(String).join(',')
      if (!newQ && !this.cardId) {
        this.setFilter(R.omit(['isCompleted'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          isCompleted: newQ,
        })
      }
    },

    // setIps :: str[] -> void
    setIps(ips) {
      const newIpQ = ips.join(',')
      if (!newIpQ && !this.cardId) {
        this.setFilter(R.omit(['ips'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          ips: newIpQ,
        })
      }
    },

    // setIps :: str[] -> void
    setHostnames(hostnames) {
      const newHostnameQ = hostnames.join(',')
      if (!newHostnameQ && !this.cardId) {
        this.setFilter(R.omit(['hostnames'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          hostnames: newHostnameQ,
        })
      }
    },

    setPorts(ports) {
      const newPortQ = ports.join(',')
      if (!newPortQ && !this.cardId) {
        this.setFilter(R.omit(['ports'], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          ports: newPortQ,
        })
      }
    },

    // setCustomField :: str -> Any -> void
    setCustomField(field, value) {
      const newFilterQ = value.join(',')
      if (!newFilterQ && !this.cardId) {
        this.setFilter(R.omit([field.name], this.filter || {}))
      } else {
        this.setFilter({
          ...this.filter,
          [field.name]: newFilterQ,
        })
      }
    },

    // setFilter :: Object<str, Any> -> Promise<Route | undefined>
    setFilter(newFilter) {
      const { $router, projectId, cardId, filter, createCard } = this

      console.warn('New filter is: ', newFilter)

      if (R.equals(filter, newFilter)) return Promise.resolve()

      let query = cardId
        ? R.assoc('cardId', cardId, newFilter)
        : newFilter
      query = { ...R.pick(NON_FILTER_PARAMS, this.$route.query), ...query }

      const next = projectId
        ? {
          name: createCard ? 'ProjectEditCard' : 'ProjectIssueList',
          params: { projectId },
          query: R.omit(['issueId'], query),
        }
        : {
          name: createCard ? 'EditCard' : 'IssueList',
          query: R.omit(['issueId'], query),
        }
      return replaceRoute($router, next)
    },

    // resetFilters :: () -> Promise<Route | undefined>
    resetFilters() {
      const { $router, resetFiltersRoute } = this
      return replaceRoute($router, resetFiltersRoute)
    },

    fetchCounters() {
      const { projectId, filter, customFilterableFields } = this
      if (customFilterableFields == null) return null
      return IssueCounter.dispatch('$countCommon', { projectId, filter })
        .catch((e) => axios.isCancel(e) ? console.info(e) : console.error(e))
    },

    getCounter(fieldName, fieldValue = null) {
      const { counters } = this
      if (fieldName === 'total') return counters?.total ?? NO_COUNTER
      return counters?.customFields?.[fieldName]?.[fieldValue] ??
        counters?.[fieldName]?.[fieldValue] ??
        NO_COUNTER
    },

    fetchAllIpsAndHostnames() {
      const { $store, projectId } = this
      if (!projectId) throw new Error('Cannot fetch filter variants outside of a project')
      return $store.dispatch('projectsSettings/getFilterVariants', { projectId })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.FiltersDrawer
  &__toolbar
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2) !important
    position: sticky
    top: 0
    z-index: 2
    background: var(--v-darkBackground-base)
    display: flex
    align-items: center
    overflow: hidden

    ::v-deep .v-toolbar__content
      display: flex
      align-items: center
      flex: 1
      padding: 0 !important

  &--mini &__toolbar ::v-deep .v-toolbar__content
    justify-content: center

  &__toolbar-title
    font-size: 18px
    line-height: 20px
    letter-spacing: 0.0015em

  &__expand
    height: 100vh !important

  &__count
    display: flex
    margin: 24px 0
    align-items: center
    justify-content: space-between
    color: white
    padding: 0 30px
    min-height: 56px

  &__count-label
    font-weight: 500
    font-size: 18px
    line-height: 19px

  &__count-value
    font-weight: bold
    font-size: 48px
    line-height: 56px

  &__filter
    padding: 8px 0
    position: relative

    &:not(&--nested) + &:before
      content: ''
      display: block
      position: absolute
      top: 0
      width: calc(100% - 30px - 30px)
      margin-left: 30px
      margin-right: 30px
      height: 1px
      border-bottom: 1px solid rgba(0, 0, 0, .12)

    &--nested:after
      display: none
</style>
